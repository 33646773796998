// 23-12-2021 : Firebase instance
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};


let app;
let messaging;
let db
document.addEventListener('DOMContentLoaded', function () {
    app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);
    db = getFirestore(app);
});


const getPushToken = async () => {
    if ('serviceWorker' in navigator) {
        let registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: "/" })

        return Notification.requestPermission().then((permission) => {
            return getToken(messaging, {
                serviceWorkerRegistration: registration,
                vapidKey: process.env.REACT_APP_VAPID_KEY
            })
        }).catch((err) => {return false });
    }
}

export { messaging, getPushToken, onMessage,db };
export default app;
