
export const LocalCountryList = () => {

    const baseUrl = process.env.REACT_APP_API_URL
    let postUrl
    if (baseUrl === "https://dev.mystudymanager.com") {
        postUrl = "dev."
    }
    else if (baseUrl === "https://val.mystudymanager.com") {
        postUrl = "val."
    }
    else if (baseUrl === "https://mystudymanager.com") {
        postUrl = ""
    }
    let regionsArray = [
        { country: "Albania", code: "355", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Andorra", code: "376", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Angola", code: "244", server: `https://${postUrl}za.mystudymanager.com` },
        { country: "Australia", code: "61", server: `https://${postUrl}au.mystudymanager.com` },
        { country: "Austria", code: "43", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Belarus", code: "375", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Belgium", code: "32", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Bosnia and Herzegovina", code: "387", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Botswana", code: "267", server: `https://${postUrl}za.mystudymanager.com` },
        { country: "Bulgaria", code: "359", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Canada", code: "1", server: `https://${postUrl}ca.mystudymanager.com` },
        { country: "Croatia", code: "385", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Cyprus", code: "537", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Czech Republic", code: "420", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Denmark", code: "45", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Estonia", code: "372", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Faroe Islands", code: "298", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Finland", code: "358", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "France", code: "33", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Germany", code: "49", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Greece", code: "30", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Hungary", code: "36", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Iceland", code: "354", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Ireland", code: "353", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Italy", code: "39", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Latvia", code: "371", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Lesotho", code: "266", server: `https://${postUrl}za.mystudymanager.com` },
        { country: "Liechtenstein", code: "423", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Lithuania", code: "370", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Luxembourg", code: "352", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Malawi", code: "265", server: `https://${postUrl}za.mystudymanager.com` },
        { country: "Malta", code: "356", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Monaco", code: "377", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Namibia", code: "264", server: `https://${postUrl}za.mystudymanager.com` },
        { country: "Netherlands", code: "31", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Poland", code: "48", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Portugal", code: "351", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Romania", code: "40", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "San Marino", code: "378", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Slovakia", code: "421", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Slovenia", code: "386", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "South Africa", code: "27", server: `https://${postUrl}za.mystudymanager.com` },
        { country: "Spain", code: "34", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Swaziland", code: "268", server: `https://${postUrl}za.mystudymanager.com` },
        { country: "Sweden", code: "46", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Switzerland", code: "41", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Ukraine", code: "380", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "United Kingdom", code: "44", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "United States", code: "1", server: `https://${postUrl}mystudymanager.com` },
        { country: "Zambia", code: "260", server: `https://${postUrl}za.mystudymanager.com` },
        { country: "Zimbabwe", code: "263", server: `https://${postUrl}za.mystudymanager.com` },
        { country: "Aland Islands", code: "358", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Guernsey", code: "44", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Holy See (Vatican City State)", code: "379", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Isle of Man", code: "44", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Jersey", code: "44", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Macedonia, The Former Yugoslav Republic of", code: "389", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Moldova, Republic of", code: "373", server: `https://${postUrl}eu.mystudymanager.com` },
        { country: "Mozambique", code: "258", server: `https://${postUrl}za.mystudymanager.com` },
        { country: "Svalbard and Jan Mayen", code: "47", server: `https://${postUrl}eu.mystudymanager.com` }
    ]

    return regionsArray
}