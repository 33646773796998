import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken, getCalandarDataapi, getallNotificationsApi, studyApiInfo, getDocumentCtmsApi, getGlobalNotificationsApi, upcomingEventsApi, getStipendApi } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Settheme: "[Settheme] Action",
  End_point: "[End_point] Action",
  Set_drop_id: "[Set_drop_id] Action",
  Set_window_value: "[Set_window_value] Action",
  Set_Search_value: "[Set_Search_value] Action",
  Route_status: "[route_status] ction",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  Global_notification_Requested: "[Request notification_global] Action",
  Global_mystipned: "[Request stipned] Action",
  Global_Calandar: "[Request calandar] Action",
  Global_siteInfo: "[Request siteinfo] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  SetStudy: "[Set study] Action",
  SetCalltime: "[Set time] Action",
  SetCtmsStudyId: "[Set ctms_studyId] Action",
  Setnotifications: "[Set notifications] Action",
  NotificationsRequested: "[Request notifications] Action",
  CtmsDocRequested: "[Request ctmsDoc] Action",
  SetCtmsDocations: "[Set ctmsDoc] Action",
  eventRequested: "[Request events] Action",
  Seteventsations: "[Set events] Action",
  Setlogo: "[Set logo] Action",
  Global_noti_loaded: "[Load notification_global] Auth API",
  Global_stipend_loaded: "[Load stipend_global] Auth API",
  Global_calandar_data: "[Load calandar_data] Auth API",
  Global_siteinfo_loaded: "[Load siteinfo_global] Auth API",
  SetRegions: "[Set regions] Action",
  SetRegionsInviteValue: "[Set regionsInvite] Action",

};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  studies: undefined,
  notifications: undefined,
  docpp: undefined,
  CtmsDoc: undefined,
  logo: undefined,
  Global_notification: undefined,
  ctms_studyID: undefined,
  upcoming_events: undefined,
  stipend: undefined,
  siteinfo: undefined,
  calandar_data: undefined,
  route_status: undefined,
  dropdown_id: undefined,
  search_value: undefined,
  window_vaue: undefined,
  end_point_url: undefined,
  colour_them: undefined,
  regions: [],
  regionInviteValue: undefined
};

export const reducer = persistReducer(
  { storage, key: "v726-demo1-auth", whitelist: ["authToken", "end_point_url", "regions"] },

  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        return {
          ...state, authToken, studies: undefined, notifications: undefined, docpp: undefined, CtmsDoc: undefined, logo: undefined, Global_notification: undefined, upcoming_events: undefined, stipend: undefined, siteinfo: undefined
        };
      }
      case actionTypes.Settheme: {
        const { theme } = action.payload;
        return { ...state, colour_them: theme };
      }
      case actionTypes.End_point: {
        const { baseurl } = action.payload;
        return { ...state, end_point_url: baseurl };

      }
      case actionTypes.Set_drop_id: {
        const id = action.payload;
        return { ...state, dropdown_id: id };

      }
      case actionTypes.Set_window_value: {
        const { window } = action.payload;
        return { ...state, window_vaue: window };
      }
      case actionTypes.Set_Search_value: {
        const value = action.payload;
        return { ...state, search_value: value };
      }

      case actionTypes.Route_status: {
        const url = action.payload;
        return { ...state, route_status: url };
      }
      case actionTypes.Register: {
        const { authToken } = action.payload;
        return {
          authToken, user: undefined, studies: undefined, notifications: undefined, docpp: undefined, CtmsDoc: undefined, logo: undefined, Global_notification: undefined, upcoming_events: undefined, stipend: undefined, siteinfo: undefined
        };
      }
      case actionTypes.Logout: {
        return initialAuthState;
      }
      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.SetStudy: {
        const { studies } = action.payload;
        return { ...state, studies };
      }
      case actionTypes.Setnotifications: {
        const { notifications } = action.payload;
        return {
          ...state, notifications, docpp: undefined,
          stipend: undefined, siteinfo: undefined, calandar_data: undefined
        };
      }
      case actionTypes.SetCtmsDocations: {
        const { CtmsDoc } = action.payload;
        return {
          ...state, CtmsDoc, notifications: undefined, upcoming_events: undefined, stipend: undefined, siteinfo: undefined, calandar_data: undefined
        };
      }
      case actionTypes.Setlogo: {
        const { logo } = action.payload;
        return { ...state, logo };
      }
      case actionTypes.Global_noti_loaded: {
        const { Global_notification } = action.payload;
        return { ...state, Global_notification };
      }
      case actionTypes.SetCalltime: {
        const { time } = action.payload;
        return { ...state, time };
      }
      case actionTypes.Global_stipend_loaded: {
        const { stipend } = action.payload;
        return {
          ...state, stipend, docpp: undefined, CtmsDoc: undefined, notifications: undefined, upcoming_events: undefined, siteinfo: undefined, calandar_data: undefined,
        };
      }
      case actionTypes.Global_calandar_data: {
        const { calandar_data } = action.payload;
        return {
          ...state, stipend: undefined, calandar_data, docpp: undefined, CtmsDoc: undefined, notifications: undefined, upcoming_events: undefined, siteinfo: undefined
        };
      }
      case actionTypes.Global_siteinfo_loaded: {
        const { siteinfo } = action.payload;
        return { ...state, siteinfo, calandar_data: undefined, docpp: undefined, CtmsDoc: undefined, notifications: undefined, upcoming_events: undefined, stipend: undefined };
      }

      case actionTypes.SetCtmsStudyId: {
        const { ctms_studyID } = action.payload;
        return { ...state, ctms_studyID };
      }

      case actionTypes.Seteventsations: {
        const { upcoming_events } = action.payload;
        return { ...state, upcoming_events, calandar_data: undefined, docpp: undefined, CtmsDoc: undefined, stipend: undefined, siteinfo: undefined };
      }
      case actionTypes.SetRegions: {
        const { region } = action.payload;
        return { ...state, regions: region };
      }
      case actionTypes.SetRegionsInviteValue: {
        const { region } = action.payload;
        return { ...state, regionInviteValue: region };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  settheme: (theme) => ({ type: actionTypes.Settheme, payload: { theme } }),
  end_point: (baseurl) => ({ type: actionTypes.End_point, payload: { baseurl } }),
  set_drodown_id: (id) => ({ type: actionTypes.Set_drop_id, payload: { id } }),
  set_window_status: (window) => ({ type: actionTypes.Set_window_value, payload: { window } }),
  set_search_value: (value) => ({ type: actionTypes.Set_Search_value, payload: { value } }),
  route_status: (url) => ({ type: actionTypes.Route_status, payload: { url } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setstudies_action: (studies) => ({ type: actionTypes.SetStudy, payload: { studies } }),
  setCallStartTime: (time) => ({ type: actionTypes.SetCalltime, payload: { time } }),
  requestNotification: (notifications) => ({
    type: actionTypes.NotificationsRequested,
    payload: { notifications },
  }),
  setnotifications_action: (notifications) => ({ type: actionTypes.Setnotifications, payload: { notifications } }),
  requesCTMSdDoc: (key) => ({
    type: actionTypes.CtmsDocRequested,
    payload: { key },
  }),
  setCtmsdDoc_action: (CtmsDoc) => ({ type: actionTypes.SetCtmsDocations, payload: { CtmsDoc } }),
  setlogo_action: (logo) => ({ type: actionTypes.Setlogo, payload: { logo } }),
  requestGlobal_notification: (notification) => ({
    type: actionTypes.Global_notification_Requested,
    payload: { notification },
  }),
  set_global_not: (Global_notification) => ({ type: actionTypes.Global_noti_loaded, payload: { Global_notification } }),
  set_global_stipend: (stipend) => ({ type: actionTypes.Global_stipend_loaded, payload: { stipend } }),
  set_calandar_data: (calandar_data) => ({ type: actionTypes.Global_calandar_data, payload: { calandar_data } }),
  set_global_siteinfo: (siteinfo) => ({ type: actionTypes.Global_siteinfo_loaded, payload: { siteinfo } }),
  setCTMS_studyID_action: (ctms_studyID) => ({ type: actionTypes.SetCtmsStudyId, payload: { ctms_studyID } }),
  requestUpcomingEvent: (upcoming_events) => ({
    type: actionTypes.eventRequested,
    payload: { upcoming_events },
  }),
  setupcoming_action: (upcoming_events) => ({ type: actionTypes.Seteventsations, payload: { upcoming_events } }),
  request_mystipned: (key) => ({
    type: actionTypes.Global_mystipned,
    payload: { key },
  }),
  request_siteInfo: (data) => ({
    type: actionTypes.Global_siteInfo,
    payload: { data },
  }),
  request_Calandar_data: (key) => ({
    type: actionTypes.Global_Calandar,
    payload: { key },
  }),
  setRegionAction: (region) => ({ type: actionTypes.SetRegions, payload: { region } }),
  setRegionInviteAction: (region) => ({ type: actionTypes.SetRegionsInviteValue, payload: { region } }),

};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });
  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });
  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    try {
      const { data: user } = yield getUserByToken();
      yield put(actions.fulfillUser(user));
    }
    catch {
      yield put(actions.fulfillUser(undefined));
    }
  });
  yield takeLatest(actionTypes.Global_notification_Requested, function* global_notification_Requested() {
    try {
      const { data: notification } = yield getGlobalNotificationsApi();
      yield put(actions.set_global_not(notification));
    }
    catch {
      yield put(actions.set_global_not(undefined));
    }

  });
  yield takeLatest(actionTypes.NotificationsRequested, function* notificationsRequested() {
    try {
      const { data: notifications } = yield getallNotificationsApi();
      yield put(actions.setnotifications_action(notifications));
    }
    catch {
      yield put(actions.setnotifications_action(undefined));
    }

  });
  yield takeLatest(actionTypes.CtmsDocRequested, function* ctmsDocRequested(key) {
    try {
      const data = yield getDocumentCtmsApi(key.payload.key)
      let CtmsDoc = data?.data?.econsents
      if (CtmsDoc?.length > 0) {
        yield put(actions.setCtmsdDoc_action(CtmsDoc));
      } else {
        yield put(actions.setCtmsdDoc_action([]));
      }
    } catch (error) {
      yield put(actions.setCtmsdDoc_action([]));
    }
  });

  yield takeLatest(actionTypes.eventRequested, function* EventRequested() {
    try {
      const { data: upcoming_events } = yield upcomingEventsApi();
      yield put(actions.setupcoming_action(upcoming_events));
    }
    catch {
      yield put(actions.setupcoming_action(undefined));
    }

  });
  yield takeLatest(actionTypes.Global_mystipned, function* global_mystipned(key) {
    try {
      const { data: stipend } = yield getStipendApi(key.payload.key);
      yield put(actions.set_global_stipend(stipend));
    }
    catch {
      yield put(actions.set_global_stipend(undefined));

    }

  });
  yield takeLatest(actionTypes.Global_siteInfo, function* global_siteInfo(data) {
    try {
      const siteinfo = yield studyApiInfo(data.payload.data.key, data.payload.data.dropdown_data);
      yield put(actions.set_global_siteinfo([siteinfo.data]));
    }
    catch {
      yield put(actions.set_global_siteinfo([]));
    }

  });
  yield takeLatest(actionTypes.Global_Calandar, function* global_Calandar() {
    try {
      const { data: calandar_data } = yield getCalandarDataapi();
      yield put(actions.set_calandar_data(calandar_data));
    } catch {
      yield put(actions.set_calandar_data(undefined));
    }
  });
}

