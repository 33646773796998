/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss"; // Standard version
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  LayoutProvider,
  SplashScreenProvider,
  AppSubheaderProvider,
} from "./_common/layout";
import { AppI18nProvider } from "./_common/i18n";

const { PUBLIC_URL } = `${process.env.API_URL}`;
_redux.setupAxios(axios, store);
ReactDOM.render(
  <AppI18nProvider>
    <LayoutProvider>
      <AppSubheaderProvider>
        <SplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </SplashScreenProvider>
      </AppSubheaderProvider>
    </LayoutProvider>
  </AppI18nProvider>,
  document.getElementById("root")
);




