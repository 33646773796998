import React, { useState } from "react"
import { useDropzone } from "react-dropzone"

function DrangDropFile({ filetype, Returndata, toggle_spinner }) {
  const [files, setFiles] = useState([])

  React.useEffect(() => {
    if (files.length > 0) {

      Returndata(files)
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])


  const { getRootProps, getInputProps } = useDropzone({
    accept: filetype,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
      // toggle_spinner()
    },
  })

  return (
    <>
      <div className="uplaod-button-wrap p-0" {...getRootProps()}>
        <div></div>

        <div className="middle-content upload-profile-img ">
          <div className="button-wrap">
            <label className="new-button" htmlFor="upload">
              + Upload File
              <input {...getInputProps()} />
            </label>
          </div>
          <p className="text-color mb-0 upload-text">Or you can drag & drop your file</p>
        </div>
      </div>
    </>
  )
}

export default DrangDropFile
