/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import Dropdown from "react-bootstrap/Dropdown"
import { useSelector, shallowEqual } from "react-redux"
import objectPath from "object-path"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import { useHtmlClassService } from "../../../_core/AppLayout"
import { toAbsoluteUrl } from "../../../../_helpers"
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns"
import { useHistory } from "react-router-dom"
import SVG from "react-inlinesvg"


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

// google logout
export function UserProfileDropdown() {
  let history = useHistory()
  let endPointUrl = useSelector((state) => state.auth.end_point_url, shallowEqual)
  const [open, setOpen] = React.useState(false)
  const { user } = useSelector((state) => state.auth)
  const uiService = useHtmlClassService()
  const handleClickOpen = () => {
    setOpen(true)
  }
  const logoutClicked = () => {
    history.push("/logout")
  }
  const handleClose = () => {
    setOpen(false)
  }

  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
    }
  }, [uiService])


  return (
    <Dropdown drop="down" alignRight data-tut="my_profile" className="">
      <div></div>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">

        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 header-user tooltip-show"
          }
        >
          <span
            className='Custom-tooltip myaccount'

          > My Account
          </span>
          <span className="symbol symbol-35 symbol-light-success">

            <span className="symbol-label font-size-h5 font-weight-bold">
              {user.ImageURL ? <img
                src={`${endPointUrl}${user.ImageURL}`}
                alt=""
                id="img"
                className="img"

              /> : <>{user.FirstName[0]} {user.LastName[0]} </>}
            </span>
          </span>
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.FirstName} {user.LastName}
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl dropdown-user-menu">
        <>
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.FirstName} {user.LastName}
                </div>

              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between p-8 bgi-size-cover bgi-no-repeat rounded-top backgroundColor "
              style={{

              }}
            >
              <div className="symbol bg-white-o-15 mr-3 my-profile-img">
                <span className="symbol-label font-size-h5 font-weight-bold">
                  {user.ImageURL ? <img
                    src={`${endPointUrl}${user.ImageURL}`}
                    alt=""
                    id="img"
                    className="img"

                  /> : <>{user.FirstName[0]} {user.LastName[0]} </>}

                </span>
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5 my-profile-text">
                {user.FirstName} {user.LastName}
              </div>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                {/* <i className="flaticon2-calendar-3 text-success" /> */}
                <SVG src="/media/misc/user-ac.svg" className="close-icon" alt="" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer hover-color">My Account</div>
              </div>
            </div>
          </Link>

          <div className="navi-separator mt-3"></div>
          {/* we are hiding log out part if admin is viewing */}
          {sessionStorage.getItem("is_admin_control") === "true" ? <div className="navi-footer  px-8 py-5">
            <button
              onClick={() => {
                localStorage.removeItem("site_id");
                localStorage.removeItem("id");
                window.close()
              }}
              className="btn btn-primary font-weight-bold log-out-btn"
            >
              Exit
            </button>
          </div>
            : <div className="navi-footer  px-8 py-5">
              <button
                onClick={handleClickOpen}
                className="btn btn-primary font-weight-bold log-out-btn"
              >
                Log Out
              </button>
            </div>}

        </div>
        <div>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className="log-out-wrap"
          >
            <DialogTitle className="popup-heading">Log Out</DialogTitle>
            <DialogContent dividers className="middle-content">
              <Typography gutterBottom className="logut-content">
                Are you sure you want to log out?
              </Typography>
            </DialogContent>
            <DialogActions className="btn-wrapper">
              <div className="card-toolbar">
                <button className="btn btn-danger mr-6" onClick={handleClose} >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={logoutClicked}
                  className="btn logout-btn"
                >
                  Log Out
                </button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
