/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useCallback } from "react"
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap"
import objectPath from "object-path"
import { useHtmlClassService } from "../../../../_core/AppLayout"
import * as auth from "../../../../../../app/modules/Auth"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { Spinner } from "react-bootstrap"
import {
  getCtmsTokenApi,
  quickSearchApi,
  getStipendQuicksearchApi,
  getPushNotification, getSingleEventApi, getPatientSourceFormByIdApi, dashboardInviteApiMethod
} from "../../../../../../app/modules/Auth/_redux/authCrud"
import { Dialog, DialogContent as MuiDialogContent, withStyles, TextField, MenuItem } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { DateConvertFunction, } from "../../../../../../app/modules/Auth/_redux/commonCode"
import { messaging, onMessage } from '../../../../../_helpers/firebase';
import { CommonPopup } from "../../../../../../app/pages/CommonPopup"
import { match } from "css-mediaquery"

export function SearchDropdown() {
  localStorage.setItem("rendercount", '1')
  const [drpdownValue, setdrpdownValue] = useState("")
  const [drpdownValueReplica, setdrpdownValueReplica] = useState(1)
  const [open, setOpen] = React.useState(false)
  const [openAdminJoinPopup, setopenAdminJoinPopup] = React.useState(false)
  const [popupStatus, setpopupStatus] = React.useState(false)
  const [quickSearchLoading, setquickSearchLoading] = React.useState(false)
  const [pushNotificationData, setpushNotificationData] = React.useState([])
  const [searchTerm, setSearchTerm] = useState("")

  const dispatch = useDispatch()
  const studiesRedux = useSelector((state) => state.auth.studies, shallowEqual)
  const colourThemRedux = useSelector((state) => state.auth.colour_them, shallowEqual)
  const [joinButtonStatus, setjoinButtonStatus] = useState(false)
  const queryParams = new URLSearchParams(window.location.search)

  // to clear console
  useEffect(() => {
    setInterval(() => {
      console.clear()
    }, 1000);
  }, []);

  // for  theme colour
  useEffect(() => {
    const sidemenuColur = colourThemRedux?.Branding_Color;
    const linkColour = colourThemRedux?.HighLight_Color;
    const footerColur = colourThemRedux?.Accent_Color;
    document.documentElement.style.setProperty('--col1-color', sidemenuColur ? sidemenuColur : '#0C2339');
    document.documentElement.style.setProperty('--col2-color', linkColour ? linkColour : '#F68723');
    document.documentElement.style.setProperty('--col3-color', footerColur ? footerColur : '#21B3C4');
  }, [colourThemRedux]);

  const ctmsStudyId = useSelector((state) => state.auth.ctms_studyID, shallowEqual)
  const notificationsRedux = useSelector((state) => state.auth.notifications, shallowEqual)
  const stipends = useSelector((state) => state.auth.stipend, shallowEqual)
  const siteinfo = useSelector((state) => state.auth.siteinfo, shallowEqual)
  const globalNotificationRedux = useSelector((state) => state.auth.Global_notification, shallowEqual)
  localStorage.setItem("Global_notification", JSON.stringify(globalNotificationRedux))
  const uiService = useHtmlClassService()
  let history = useHistory()
  const user = useSelector((state) => state.auth.user, shallowEqual)
  // to fix esource PatientCTMSID issue
  if (sessionStorage.getItem("is_form_link_vliad" !== "yes")) {
    localStorage.setItem("PatientCTMSID", user.PatientCTMSID)
  }
  useEffect(() => {
    setSearchTerm("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    searchvalue = ""
  }, [notificationsRedux, stipends, siteinfo])
  // when user chnages we are changing PatientCTMSID
  useEffect(() => {
    if (sessionStorage.getItem("mobile_form_is_open") !== "yes") {
      localStorage.setItem("PatientCTMSID", user.PatientCTMSID)
    }
  }, [user]);
  // redux call to get studies
  useEffect(() => {
    if (studiesRedux) {
      // if admin is from single site  we are showing drodown with single site   //  when admin is accesing user account
      if (sessionStorage.getItem("is_admin_control") === "true") {
        const studyIdForFilter = JSON.parse(sessionStorage.getItem("study_id_for_pp"))
        const specificItem = studiesRedux.find((item) => item.id === studyIdForFilter)
        const hostlinkForFilter = specificItem?.sites?.CTMSHostLink
        const filteredDataForDropdown = studiesRedux.filter((item) => item?.sites?.CTMSHostLink === hostlinkForFilter)
        setdropdownData(filteredDataForDropdown)
        localStorage.setItem("dropdowndata", filteredDataForDropdown)

        if (filteredDataForDropdown?.length > 0) {
          setdropdownData(filteredDataForDropdown)
          localStorage.setItem("dropdowndata", filteredDataForDropdown)
        } else {
          // removing this id after deleteing account by admin
          sessionStorage.removeItem("study_id_for_pp")
          setdropdownData(studiesRedux)
          localStorage.setItem("dropdowndata", JSON.stringify(studiesRedux))

        }
      }
      else {
        setdropdownData(studiesRedux)
        localStorage.setItem("dropdowndata", JSON.stringify(studiesRedux))
      }
    }
  }, [studiesRedux])

  const [dropdownData, setdropdownData] = useState([])
  const [dropdownDataTemp, setdropdownDataTemp] = useState([
    { id: 1, value: "No studies assigned" },
  ])
  const [dropdownSelectedData, setdropdownSelectedData] = useState([])
  // to get default value of dropdown
  useEffect(() => {
    // if admin is present this block will execute
    if (sessionStorage.getItem("is_admin_control") === "true" && sessionStorage.getItem("study_id_for_pp")) {
      setAdminDropdown(sessionStorage.getItem("study_id_for_pp"))
    }
    else {
      setTimeout(() => {
        //geting cookies value to set dropdown value
        const cookieUserid = ('; ' + document.cookie).split(`; userId=`).pop().split(';')[0];
        const cookieSiteid = ('; ' + document.cookie).split(`; siteId=`).pop().split(';')[0];

        // if cookies are present we are setting previously select study
        if (cookieUserid === localStorage.getItem("id") && cookieSiteid) {
          setAdminDropdown(cookieSiteid)
        } else {
          setdropdownSelectedData(dropdownData[0])
          if (dropdownData.length > 0) {
            setdrpdownValue(dropdownData[0].id)
            localStorage.setItem("id_for_dropdown", dropdownData[0].id)
            sessionStorage.setItem("id_for_dropdown", dropdownData[0].id)
          }
        }

      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownData])

  // we are setting dropdown value if admin is there
  const setAdminDropdown = (value, type) => {
    if (dropdownData?.length > 0) {
      const num = Number(value)
      let specificItem;
      if (type === 2) {
        specificItem = dropdownData.find((item) => item.CTMSStudyID === num)
      } else {
        specificItem = dropdownData.find((item) => item.id === num)
      }
      // if  no result found
      if (specificItem) {
        localStorage.setItem("site_id", specificItem?.sites?.id)
        dispatch(auth.actions.requestUser())
        type == 2 ? setdrpdownValue(specificItem?.id) : setdrpdownValue(value)
        setdropdownSelectedData(specificItem)
      } else {
        // no result found so set default value
        setdropdownSelectedData(dropdownData[0])
        if (dropdownData.length > 0) {
          setdrpdownValue(dropdownData[0].id)
          localStorage.setItem("id_for_dropdown", dropdownData[0].id)
          sessionStorage.setItem("id_for_dropdown", dropdownData[0].id)
        }
      }

    }
  }
  // setting value of dropdown in local storage for api calls
  useEffect(() => {
    if (dropdownSelectedData?.id) {
        //setting cookies value to set dropdown value
      document.cookie = "userId=" + localStorage.getItem("id");
      document.cookie = "siteId=" + dropdownSelectedData.id;

      localStorage.setItem("CTMSStudyID", dropdownSelectedData.CTMSStudyID)
      localStorage.setItem("SiteID_local", dropdownSelectedData.sites?.id)
      localStorage.setItem("StudyID_local", dropdownSelectedData.id)
      // when esource is open throgh mobile we dont want to store CTMSHostLink
      if (sessionStorage.getItem("is_form_link_vliad") !== "true") {
        localStorage.setItem("CTMSHostLink", dropdownSelectedData.sites?.CTMSHostLink)

      }
      localStorage.setItem("site_id", dropdownSelectedData.sites?.id)
      localStorage.setItem("site_obj", JSON.stringify(dropdownSelectedData))
      dispatch(auth.actions.settheme(null))
      setCtmsKey()
      let colourcode = {
        Accent_Color: dropdownSelectedData.sites?.Accent_Color,
        Branding_Color: dropdownSelectedData.sites?.Branding_Color,
        HighLight_Color: dropdownSelectedData.sites?.HighLight_Color
      }
      if (queryParams.get("is_mob") !== "true") {
        dispatch(auth.actions.settheme(colourcode))
      }
      dispatch(auth.actions.setlogo_action(dropdownSelectedData.sites.SiteLogoURL))
      dispatch(auth.actions.requestUser())
      dispatch(auth.actions.requestGlobal_notification())
      dispatch(auth.actions.setCTMS_studyID_action(null))

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownSelectedData])
  // to set sessionkey for single time
  const setCtmsKey = () => {
    getCtmsTokenApi()
      .then((value) => {
        sessionStorage.setItem("sessionKeyValue", value.data)
        sessionStorage.setItem("randomvalue", Math.random())
        triggerReduxCall()
      })
      .catch((error) => {
        triggerReduxCall()
      })
  }

  const triggerReduxCall = () => {
    const pathname = window.location.pathname
    // when meeting url is share and user is not login then we 
    // are storing that url and open in window when user login
    let meetingUrl = sessionStorage.getItem("meeting_url")
    if (meetingUrl) {
      window.open(meetingUrl, "_self")
      sessionStorage.removeItem("meeting_url")
    }
    if (pathname === "/user-profile/personal-information-readonly") {
      dispatch(auth.actions.requestNotification())
    }
    if (pathname === "/my-documents") {
      dispatch(auth.actions.requesCTMSdDoc(sessionStorage.getItem("sessionKeyValue")))
    }
    if (pathname === "/notification" || pathname === "/my-form") {
      dispatch(auth.actions.requestNotification())
    }
    if (pathname === "/dashboard") {
      dispatch(auth.actions.requestUpcomingEvent())
    }
    if (pathname === "/my-calendar") {
      dispatch(auth.actions.request_Calandar_data())
    }
    if (pathname === "/global-search") {
      history.push("/dashboard")
    }
    if (pathname === "/more-details" && sessionStorage.getItem("PushNotification") !== "yes") {
      history.push("/my-calendar")
    }
    if (pathname === "/my-stipends") {
      dispatch(auth.actions.request_mystipned(sessionStorage.getItem("sessionKeyValue")))
    }
    if (pathname === "/site-info") {

      const dropdownDataValue = JSON.parse(localStorage.getItem("site_obj"))
      const combinedata = {
        key: sessionStorage.getItem("sessionKeyValue"),
        dropdown_data: dropdownDataValue,
      }
      dispatch(auth.actions.request_siteInfo(combinedata))

    }
    // this will redirect  to forms list  as on study change  form should also change (url parameter)
    if (pathname === "/form-details") {
      if (sessionStorage.getItem("mobile_form_is_open") !== "yes" && sessionStorage.getItem("esourcemailRequest") !== "true" && sessionStorage.getItem("PushNotification") !== "yes"
      ) {
        history.push("/my-form")
      }
      else {
        // if esource is open from mail link we are changing dropdown
        if (sessionStorage.getItem("esourcemailRequest") === "true") {
          const queryParams = new URLSearchParams(window.location.search)
          const ctmsStudyId = queryParams.get("CTMSStudyID")
          if (ctmsStudyId) {
            setAdminDropdown(ctmsStudyId, 2)
          }
        }
      }

    }
  }
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.search.layout") === "offcanvas",
      headerLogo: uiService.getLogo(),
    }
  }, [uiService])

  const handleChange = (event) => {
    const num = Number(event)
    const specificItem = dropdownData.find((item) => item.id === num)
    localStorage.setItem("site_id", specificItem?.sites?.id)
    setdrpdownValue(event)
    localStorage.setItem("id_for_dropdown", event)
    sessionStorage.setItem("id_for_dropdown", event)
    setdropdownSelectedData(specificItem)
  }

  useEffect(() => {
    if (ctmsStudyId) {
      const num = ctmsStudyId
      const specificItem = dropdownData.find((item) => item.id === num)
      if (specificItem) {
        localStorage.setItem("site_id", specificItem.sites.id)
        dispatch(auth.actions.requestUser())
        setdrpdownValue(specificItem.id)
        setdropdownSelectedData(specificItem)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctmsStudyId])

  // popup methods
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent)
  const handleClose = () => {
    setOpen(false)
  }

  // popup methods end
  let searchvalue
  const QuicksearchOp = (e) => {
    searchvalue = e
    setSearchTerm(e)
  }

  const goforSearchPage = (e) => {
    e.preventDefault()
    const pathname = window.location.pathname
    if (pathname !== "/global-search" && searchTerm.length < 1) {
      return
    }
    setquickSearchLoading(true)
    if (searchTerm.trim().length > 0) {
      quickSearchApi(searchTerm)
        .then((data) => {
          getStipendSearchData(searchTerm, data.data, sessionStorage.getItem("sessionKeyValue"))
        })
        .catch((err) => {
          setquickSearchLoading(false)
        })
    } else {
      setTimeout(() => {
        history.push({
          pathname: "/global-search",
          state: { data: [], name: "", stipend: [] },
        })
      }, 10)

      setquickSearchLoading(false)
    }
  }

  // to reset search
  const resetsearch = () => {
    setSearchTerm("")
    let pathname = window.location.pathname
    searchvalue = ""
    if (pathname === "/global-search") {
      history.push({
        pathname: "/global-search",
        state: { data: [], name: "", stipend: [] },
      })
    }
  }

  // to get stipend api
  const getStipendSearchData = (searchvalue, eventdata, SessionKey) => {
    getStipendQuicksearchApi(SessionKey, searchvalue)
      .then((data_) => {
        setquickSearchLoading(false)
        history.push({
          pathname: "/global-search",
          state: { data: eventdata, name: searchvalue, stipend: data_.data },
        })
      })
      .catch((data) => {
        setquickSearchLoading(false)
      })
  }
  // clearing console  and calling push notification
  useEffect(() => {
    const pathnameValue = window.location.pathname
    if (studiesRedux?.length > 0) {
      if (localStorage.getItem('rendercount') === '1' && pathnameValue !== "/auth/login" && pathnameValue !== "/auth/registration") {
        call_push_api()
      }
    }
    return () => {
      clearTimeout(timeInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studiesRedux])

  // redirect to calendar page
  const openMoredetail = (data) => {
    history.push({
      pathname: "/more-details",
      state: data,
    })
  }

  // to show popup after every 5 min
  let timeInterval;
  const call_push_api = () => {
    localStorage.setItem('rendercount', '2')
    handleClose()
    timeInterval = setInterval(() => {
      getPushNotification().then(data => {
        setpushNotificationData(data.data[0].EventNotifications)
        if (data.data[0].EventNotifications.length > 0 && !open) {
          setOpen(true)
        }
        if (data.data[0].EventNotifications.length === 0) {
          setOpen(false)
        }
      })
    }, 180000);
  }

  useEffect(() => {
    if (sessionStorage.getItem("is_admin_control") === "true") {
      const tidyUp = (event) => {
        if (event.persisted) {
          return
        }
        localStorage.removeItem("site_id");
        localStorage.removeItem("id");
      }
      return () => {
      }
    }
  }, [])


  // for firebase notification 
  onMessage(messaging, (payload) => {
    dispatch(auth.actions.requestGlobal_notification())
    const pathnameValue = window.location.pathname
    // to chnage dropdown enrollent status we will call that api only if this block execute
    if (payload.notification.title === "Enrollment Status Update") {
      sessionStorage.setItem("Enrollment_status", "true")
    }
    // to revoke inite send by mistek
    if (payload.notification.title === "Revoke Study" || payload.notification.title === "Study Invites") {
      sessionStorage.setItem("RevokeStudy", "true")
    }
    // when ctms user join call we want to show popup
    if (payload.notification.title === "Calendar Event") {
      sessionStorage.setItem("twilio_ctms_user_status", "true")
    }
    // when user changes email addres we are forcefully making that user logout
    if (payload.notification.title === "Logout") {
      dispatch(auth.actions.logout())
    }
    if (pathnameValue === "/notification" || pathnameValue === "/dashboard" || pathnameValue === "/my-form") {
      dispatch(auth.actions.requestNotification())
    }
    if (Notification.permission === 'granted') {
      let notification = new Notification(payload.notification.title, {
        body: payload.notification.body,
        // image:""
        // icon:""
      })
    }
  })
  // when window loses focous and gets in focous
  const changeDropdownValue = useCallback(() => {
    setdrpdownValue(localStorage.getItem("id_for_dropdown"))
    let temp_dropdowndata = JSON.parse(localStorage.getItem("dropdowndata"))
    const num = Number(localStorage.getItem("id_for_dropdown"))
    const specificItem = temp_dropdowndata?.find((item) => item.id === num)
    setdropdownSelectedData(specificItem)
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === "visible") {
        if (sessionStorage.getItem("visibilitychange") !== "yes") {
          sessionStorage.setItem("visibilitychange", "yes")
          // to set dropdownvalue if multiple tabs are open and then changes dropdown
          if (localStorage.getItem("id_for_dropdown") !== sessionStorage.getItem("id_for_dropdown")) {
            // avoiding for admin view
            if (sessionStorage.getItem("is_admin_control") !== "true") {
              changeDropdownValue()
            }
          }
          const pathnameValue = window.location.pathname
          if (pathnameValue !== "/auth/login") {
            dispatch(auth.actions.requestGlobal_notification())
          }
          if ((pathnameValue === "/notification" || pathnameValue === "/dashboard" || pathnameValue === "/my-form") && pathnameValue !== "/auth/login") {
            dispatch(auth.actions.requestNotification())
          }
          // when email chamges and background push notification arrives we are storing that
          if (sessionStorage.getItem("email_changed") === "yes") {
            dispatch(auth.actions.logout())
            sessionStorage.removeItem("email_changed")
          }
        }

      } else {
        // we will compare this arrray  before and after  for push notification
        sessionStorage.removeItem("visibilitychange")
        const globalNotificationLocalStored = localStorage.getItem("Global_notification")
        localStorage.setItem("Global_notification2", globalNotificationLocalStored ? globalNotificationLocalStored : [])
      }
    });

    return () => {
      document.removeEventListener('visibilitychange', () => {
      });

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when push notification arrives we are calling this 
  useEffect(() => {
    const pathnameValue = window.location.pathname
    if (globalNotificationRedux) {
      // when app is in background and gets in focus we are calling api to change enrolletment status
      let notificationValue = localStorage.getItem("Global_notification2")
      if (notificationValue?.length > 10) {
        // comparing two notification before and after if count is differnt
        if (JSON.parse(notificationValue).length !== globalNotificationRedux?.length) {
          if (globalNotificationRedux[0]?.App_Route === "DetailScreen" && globalNotificationRedux[0]?.Description === "Your Doctor / Study Coordinator has joined virtual visit for ") {
            sessionStorage.setItem("twilio_ctms_user_status", "true")
          }
        }

      }
      if (sessionStorage.getItem("twilio_ctms_user_status") === "true" && globalNotificationRedux[0]?.Description === "Your Doctor / Study Coordinator has joined virtual visit for ") {
        sessionStorage.removeItem("twilio_ctms_user_status")
        const notificationArrivedDate = new Date(globalNotificationRedux[0]?.createdAt)
        // cheking when notification arrived if it is with in 4 min we are showing popup
        if (new Date().getTime() - notificationArrivedDate.getTime() < 250000 && pathnameValue !== "/meeting") {
          if (sessionStorage.getItem("popupIsOpen") !== "yes") {
            setopenAdminJoinPopup(true)
            sessionStorage.setItem("popupIsOpen", "yes")
          }
        }
      }
    }

  }, [globalNotificationRedux]);

  //  popup start

  const handleCloses = () => {
    sessionStorage.removeItem("popupIsOpen")
    setopenAdminJoinPopup(false)
  }
  // when click on join button on popup
  const joinTwilioCall = async () => {
    setjoinButtonStatus(true)
    sessionStorage.removeItem("popupIsOpen")
    await getCtmsTokenApi(globalNotificationRedux[0].SiteID)
      .then((value) => {
        localStorage.setItem("join_status_id", globalNotificationRedux[0]?.DocID)
        getSingleEventApi(globalNotificationRedux[0]?.DocID, value.data, globalNotificationRedux[0].CTMSHostLink).then(result => { joincallPart2(result.data) }).catch(err => {
          setjoinButtonStatus(false)
        })
      })
      .catch((error) => {
        setjoinButtonStatus(false)
      })
  }

  const joincallPart2 = (data) => {
    setjoinButtonStatus(false)

    if (data[0].IsInitiates === true) {
      localStorage.setItem("CTMSEventID", globalNotificationRedux[0]?.CTMSEventID)
      localStorage.setItem("join_status_id", globalNotificationRedux[0]?.DocID)
      sessionStorage.setItem("RoomSID", data[0].RoomSID)
      // to change dropdown value
      setAdminDropdown(globalNotificationRedux[0]?.StudyID)
      const num = Number(globalNotificationRedux[0]?.StudyID)
      const specificItem = dropdownData.find((item) => item.id === num)
      let StudyNameForEvents = specificItem.CTMSDescription === "" ? ' (' + specificItem.CTMSStudySponsor + ', ' + specificItem.CTMSStudyProtocol + ")" : specificItem.CTMSDescription + ' (' + specificItem.CTMSStudySponsor + ', ' + specificItem.CTMSStudyProtocol + ")";
      let titleIs = globalNotificationRedux[0].EventVisitName + ' - ' + StudyNameForEvents
      setopenAdminJoinPopup(false)
      history.push({
        pathname: "/meeting",
        state: { title: globalNotificationRedux[0]?.Title + globalNotificationRedux[0]?.CTMSEventID, only_title: globalNotificationRedux[0]?.eventName },
      })
    }
    else {
      setpopupStatus(true)
      setopenAdminJoinPopup(false)
    }
  }
  const closePopup = () => {
    setpopupStatus(false)
  }
  // redirect to meeting
  const joinEvent = (data) => {
    localStorage.setItem("join_status_id", data?.id)
    getSingleEventApi(data?.id).then(result => { joinEventPart2(result.data) }).catch(err => { })
  }

  const joinEventPart2 = (data) => {
    if (data[0].IsInitiates === true) {
      localStorage.setItem("CTMSEventID", data[0]?.CTMSEventID)
      localStorage.setItem("join_status_id", data[0]?.id)
      sessionStorage.setItem("RoomSID", data[0].RoomSID)
      // to change dropdown value
      setAdminDropdown(data[0]?.StudyID)
      const num = Number(data[0]?.StudyID)
      let dropdownValueObject = JSON.parse(localStorage.getItem("site_obj"))
      let typeIs = data[0]?.Type ? " - Visit Type (" + data[0]?.Type + ")" : ""
      let StudyNameForEvents = dropdownValueObject.CTMSDescription === "" ? ' (' + dropdownValueObject.CTMSStudySponsor + ', ' + dropdownValueObject.CTMSStudyProtocol + ")" : dropdownValueObject.CTMSDescription + ' (' + dropdownValueObject.CTMSStudySponsor + ', ' + dropdownValueObject.CTMSStudyProtocol + ")";
      let visitTitle = (data[0]?.VisitName === "" ? (data[0]?.Title + ' - ') : (data[0]?.VisitName + ' - ')) + StudyNameForEvents + typeIs
      setopenAdminJoinPopup(false)
      history.push({
        pathname: "/meeting",
        state: { title: data[0]?.Title + data[0]?.CTMSEventID, only_title: visitTitle },
      })
    }
    else {
      setpopupStatus(true)
      setopenAdminJoinPopup(false)
    }
  }


  // Method to Get patient source form details by id and redirecting user to that form
  const getPatientSourceForm = (docid) => {
    const dropdownData = JSON.parse(localStorage.getItem("dropdowndata"))

    getPatientSourceFormByIdApi(docid).then((data) => {
      if (data.data?.eSourceForms.length > 0) {
        let formDetail = data.data?.eSourceForms[0]
        const specificItem = dropdownData.find((item) => item.id === formDetail?.StudyID)

        if (specificItem) {
          setTimeout(() => {
            let portalId = localStorage.getItem("id")
            let patientCtmsId = localStorage.getItem("PatientCTMSID")
            let ctmsHostLink = specificItem?.sites?.CTMSHostLink.slice(8)
            let siteIdLocal = specificItem?.sites?.id
            history.push("/form-details?CTMSHostLink_=" + ctmsHostLink + "&FormID_=" + formDetail?.FormID + "&is_mob=" + false + "&PatientCTMSID_=" + patientCtmsId + "&SiteID_local_=" + siteIdLocal + "&FormStatus_=" + formDetail?.Status + "&ID_=" + formDetail?.id + "&CreatedAt_=" + formDetail?.AssignDate + "&id=" + portalId)
          }, 1500)
        }
      } else {
        history.push("/my-form")
      }
      setTimeout(() => { sessionStorage.removeItem("PushNotification") }, 2500)
    })
  }

  // Method to get the calendar event details by id and redirecting user to that event
  const getEventDetails = (eventid) => {
    getSingleEventApi(eventid)
      .then((data) => {
        if (data.data.length > 0) {
          let eventDetail = data.data?.[0]
          history.push({
            pathname: "/more-details",
            state: eventDetail,
          })
        }
        setTimeout(() => { sessionStorage.removeItem("PushNotification") }, 2500)
      })
  }

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.onmessage = ({ data }) => {
        let msg = data?.msg

        if (msg && msg?.PatientID == localStorage.getItem("id")) {
          getCtmsTokenApi().then((value) => {
            if (msg?.Web_Route == "MyForm") {
              history.push("/my-form")
              dispatch(auth.actions.setCTMS_studyID_action(msg?.StudyID))
              sessionStorage.setItem("PushNotification", "yes")
              getPatientSourceForm(msg?.DocID)
            } else if (msg?.Web_Route == "More_details") {
              dispatch(auth.actions.setCTMS_studyID_action(msg?.StudyID))
              sessionStorage.setItem("PushNotification", "yes")
              getEventDetails(msg?.DocID, value.data)
            } else if (msg?.Web_Route == "dashboard") {
              if (msg?.NotificationTemplateID != 1 && msg?.NotificationTemplateID != 19) {
                dispatch(auth.actions.setCTMS_studyID_action(msg?.StudyID))
              }
              history.push("/dashboard")
            } else if (msg?.Web_Route[1] === "t") {
              window.open(msg.Web_Route)
            } else if (msg?.Web_Route == "EnrollmentStatus") {
              dispatch(auth.actions.setCTMS_studyID_action(msg?.StudyID))
              history.push("/dashboard")
            } else if (msg?.Web_Route === "My_documents") {
              dispatch(auth.actions.setCTMS_studyID_action(msg?.StudyID))
              if (msg?.Description.includes("signature")) {
                history.push("/my-documents/0")
              } else if (msg?.Description.includes("completed")) {
                history.push("/my-documents/1")
              } else {
                history.push("/my-documents")
              }
            }
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // to clear user data if user is not activefor  20 min
  useEffect(() => {
    const windowFunctionBeforeLoad = (event) => {
      if (event.persisted) {
        return
      }
      localStorage.setItem("lastLoginTime", new Date())

    }

    window.addEventListener("pagehide", windowFunctionBeforeLoad)
    window.addEventListener("beforeunload", windowFunctionBeforeLoad)

    return () => {
      window.removeEventListener("pagehide", windowFunctionBeforeLoad)
      window.removeEventListener("beforeunload", windowFunctionBeforeLoad)
    }
  }, [])

  // when we revoke invite on pages other than dashboard
  useEffect(() => {
    const pathname = window.location.pathname
    if (globalNotificationRedux && pathname!=="dashboard") {
      // when app is in background and gets in focus we are calling api to change enrolletment status
      let localGlobalNotification = localStorage.getItem("Global_notification2")
      if (localGlobalNotification !== undefined && localGlobalNotification !== null && localGlobalNotification !== "undefined" && localGlobalNotification !== "null" && localGlobalNotification !== "") {
        // comparing two notification before and after if count is differnt
        if (JSON.parse(localGlobalNotification).length !== globalNotificationRedux?.length) {
          if (globalNotificationRedux[0]?.App_Route === "EnrollmentStatus") {
            sessionStorage.setItem("Enrollment_status", "true")
          }
          if (globalNotificationRedux[0]?.App_Route === "Home") {
            sessionStorage.setItem("RevokeStudy", "true")
          }
        }
      }
      // for revoke invite, to avoid multiple api call
      if (sessionStorage.getItem("RevokeStudy") === "true") {
        dashboardInviteApiMethod()
          .then((value) => {
            if (globalNotificationRedux[0]?.Description.includes("revoked") && value.data[0]?.studies?.length === 0) {
              if (value.data[1]?.studyInvites?.length > 0) {
                localStorage.setItem("site_id", value.data[1]?.studyInvites[0]?.sites.id)
              } else {
                localStorage.setItem("site_id", null)
              }
            }
          })
          .catch((error) => { })
      }

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalNotificationRedux]);



  return (
    <>
      {/* to show common popup */}
      <CommonPopup popupstatus={popupStatus} closePopup={closePopup} />
      {/* dialog start */}
      <Dialog
        onClose={(event, reason) => {
          if (true) {
            handleClose()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="event-reminder"
      >
        <DialogContent className="middle-content Event-reminder-wrap">
          <div className=" top-header">
            <div className="top-header-contet">
              <div className="d-flex align-items-center">
                <img
                  src="/media/svg/icons/Code/bell.svg"
                  className="reminder-icon mr-2 "
                  alt=""
                />
                <h4 className="mb-0">Event Reminder</h4>
              </div>

              <img
                src="/media/misc/close.svg"
                className="details-icon "
                onClick={handleClose}
                alt=""
              />
            </div>

          </div>
          <div className="event-details-text">
            <p className="text">You have the following event to attend <span className="color-text"></span></p>
          </div>
          {pushNotificationData.map((value, index) => {
            let dropdownValueObject = JSON.parse(localStorage.getItem("site_obj"))
            let typeIs = value?.Type ? " - Visit Type (" + value?.Type + ")" : ""
            let StudyNameForEvents = dropdownValueObject.CTMSDescription === "" ? ' (' + dropdownValueObject.CTMSStudySponsor + ', ' + dropdownValueObject.CTMSStudyProtocol + ")" : dropdownValueObject.CTMSDescription + ' (' + dropdownValueObject.CTMSStudySponsor + ', ' + dropdownValueObject.CTMSStudyProtocol + ")";
            let visitTitle = (value?.VisitName === "" ? (value?.Title + ' - ') : (value?.VisitName + ' - ')) + StudyNameForEvents + typeIs
            return [
              <div className="event-details " key={index}>
                <div className='d-flex align-items-start event-content'>
                  <h5 className="event-name">{visitTitle}</h5>
                  <img
                    src="/media/misc/details-gray.svg"
                    className="details-icon "
                    onClick={() => {
                      handleClose()
                      openMoredetail(value)
                      handleClose()
                    }
                    }
                    alt=""
                  />
                </div>
                <div className="time-wrap">
                  <div className="d-flex align-items-center mr-2">
                    <div>
                      <img
                        className="clock-icon"
                        src="/media/misc/wall-clock.png"
                        alt=""
                      />
                    </div>
                    <span className="event-time">{DateConvertFunction(value?.StartDate, 1)}</span>
                  </div>
                  <button className="join-button" type="button" onClick={() => {
                    joinEvent(value)
                    handleClose()
                  }}  > Join</button>

                </div>

              </div>
            ]
          })}
        </DialogContent>
      </Dialog>

      {/* dialog end */}
      <div className="topbar-search">
        <div className="select-study-drop">
          <label>Select Study </label>
          {studiesRedux?.length > 0 ?

            <>
              <TextField
                select
                className="select-dropdown"
                id="study_select_dropdown"
                value={drpdownValue || drpdownValueReplica}
                onChange={(e) => { handleChange(e.target.value) }}
                disabled={dropdownData?.length < 2}
              >

                {dropdownData?.length < 1
                  ? dropdownDataTemp.map((data, index) => (
                    <MenuItem value={data.id} key={index} id={"select-" + index} >No studies assigned</MenuItem>
                  ))
                  : dropdownData.map((data, index) => (
                    <MenuItem key={data.id} value={data.id} id={"select-" + index} className="dropdownfor">
                      <p>
                        <b>
                          {data.sites.CTMSSiteTitle}{" "}
                          {data.CTMSDisplayedOnPatientPortal || data.CTMSIndication
                            ? " -"
                            : ""}{" "}
                          {data.CTMSDisplayedOnPatientPortal
                            ? data.CTMSDisplayedOnPatientPortal
                            : data.CTMSIndication}{" "}
                        </b>
                        ( {data.CTMSStudySponsor}, {data.CTMSStudyProtocol} )
                        <b>{data.patientstudies[0].EnrollmentStatus !== null ? " - " + data.patientstudies[0].EnrollmentStatus : "-NA"}</b>

                      </p>
                    </MenuItem>

                  ))}
              </TextField>
              <span className="Custom-tooltip"> {`
              ${dropdownSelectedData?.sites?.CTMSSiteTitle} ${dropdownSelectedData?.sites?.CTMSSiteTitle !== "" ? " - " : ""
                } ${dropdownSelectedData?.CTMSDisplayedOnPatientPortal === ""
                  ? dropdownSelectedData?.CTMSIndication
                  : dropdownSelectedData?.CTMSDisplayedOnPatientPortal
                } ${"("}
              ${dropdownSelectedData?.CTMSStudySponsor}  ${","}${dropdownSelectedData?.CTMSStudyProtocol
                } ${")"} - ${dropdownSelectedData?.patientstudies ? dropdownSelectedData.patientstudies[0]?.EnrollmentStatus!==null?dropdownSelectedData.patientstudies[0]?.EnrollmentStatus:"NA" : "NA"}

            `}</span>

            </>
            :
            <>
              <TextField
                select
                className="select-dropdown"
                id="study_select_dropdown"
                value={drpdownValue || drpdownValueReplica}
                onChange={(e) => { handleChange(e.target.value) }}
                disabled={dropdownData?.length < 2}
              >
                {dropdownData?.length < 1
                  ? dropdownDataTemp.map((data, index) => (
                    <MenuItem value={data.id} key={index} id={"select-" + index} >No studies assigned</MenuItem>
                  ))
                  : dropdownData.map((data, index) => (
                    <MenuItem key={data.id} value={data.id} id={"select-" + index} className="dropdownfor">
                      <p>
                        <b>
                          {data.sites.CTMSSiteTitle}{" "}
                          {data.CTMSDisplayedOnPatientPortal || data.CTMSIndication
                            ? "-"
                            : ""}{" "}
                          {data.CTMSDisplayedOnPatientPortal
                            ? data.CTMSDisplayedOnPatientPortal
                            : data.CTMSIndication}{" "}
                        </b>
                        ( {data.CTMSStudySponsor}, {data.CTMSStudyProtocol})
                        <b>{data.patientstudies[0].EnrollmentStatus !== null ? "- " + data.patientstudies[0].EnrollmentStatus : ""}</b>

                      </p>
                    </MenuItem>

                  ))}
              </TextField>
              <span className="Custom-tooltip">  No studies assigned</span>
            </>
          }

        </div>

        {!layoutProps.offcanvas && (
          <Dropdown
            alignRight
            drop="down"
            onToggle={() => {

            }}
            id="kt_quick_search_toggle"
          >
            <form className="height-100">
              <div className="search-wrap height-100">
                <div className="tooltip-show">
                  <input
                    className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1 search-box"
                    type="Search"
                    placeholder="Search calendar events, stipends, study consents and visit forms..."
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => QuicksearchOp(e.target.value)}
                    maxLength={51}
                  />
                  {!searchTerm && <span
                    className='Custom-tooltip  '
                  >  Search calendar events by name or date, consent documents , visit forms by name, visit stipends by visit name
                  </span>}
                </div>
                {quickSearchLoading && (
                  <Spinner animation="border" className="spinner-info" variant="primary" />
                )}
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="user-notification-tooltip"
                    >
                      Clear
                    </Tooltip>
                  }
                >
                  <button type="button" className="close-icon-btn" onClick={resetsearch}>
                    <img src="/media/misc/clear.svg" className="close-icon" alt="" />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip
                      id="user-notification-tooltip"
                    >
                      Search
                    </Tooltip>
                  }
                >
                  <button
                    type="submit"
                    className="search-icon-btn"
                    onClick={(e) => { goforSearchPage(e) }}
                  >
                    <img src="/media/misc/search_icon.svg" className="search-icon" alt="" />
                  </button>
                </OverlayTrigger>
              </div>
            </form>
          </Dropdown>
        )}
      </div>

      {/* popups  admin joined call reminder */}
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloses()
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={openAdminJoinPopup}
        className="success-popup"
      >
        <DialogContent dividers className="text-center p-10">
          <div className="card-toolbar">
            <div>Your Doctor / Study Coordinator has joined the virtual visit for<span className="text_style"> {globalNotificationRedux ? globalNotificationRedux[0]?.eventName : ""}</span> </div>
            <div className="card-button-wrap">
              <button className="btn btn-danger mr-6" onClick={handleCloses} >
                Cancel
              </button>
              <button
                type="submit"
                onClick={joinTwilioCall}
                className="btn btn-primary logout-btn"
                disabled={joinButtonStatus}
              >
                Join
                <img src="/media/misc/on-video.svg" className="video-icon" alt="video" />
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default React.memo(SearchDropdown)