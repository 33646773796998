/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable no-unused-vars
import React, { useEffect, useState } from "react"
import { Suspense, lazy } from "react";
import { Switch, Redirect } from "react-router-dom"
import { ContentRoute, LayoutSplashScreen } from "../../../../_common/layout"
import Login from "./Login"
import { Carousel } from 'react-bootstrap';
import { getCarouselImgApi } from "../_redux/authCrud"
import 'bootstrap/dist/css/bootstrap.css'
import "../../../../_common/_assets/sass/pages/login/login.scss"
import { useSelector, shallowEqual } from "react-redux"
const Registration = lazy(() =>
  import("./Registration")
);
const ResetPassword = lazy(() =>
  import("./ResetPassword")
);
const Feedback = lazy(() =>
  import("./Feedback")
);
const VideochatCtms = lazy(() =>
  import("./VideochatCtms")
);
const RegisterEmailVerify = lazy(() =>
  import("./RegisterEmailVerify")
);
const ForgotPassword = lazy(() =>
  import("./ForgotPassword")
);
const Policies = lazy(() =>
  import("./Policies")
);

const Cookiespage = lazy(() =>
  import("./Cookiespage")
);
const TermsOfUse = lazy(() =>
  import("./TermsOfUse")
);

function AuthPage() {
  const urlForCarousel = useSelector((state) => state.auth.route_status, shallowEqual)
  const [carouselData, setcarouselData] = useState([])

  useEffect(() => {
    if (urlForCarousel) {
      getCarouselImgApi(urlForCarousel?.url).then(data => { setcarouselData(data.data) }).catch(err => { })
    }
  }, [urlForCarousel])


  return (
    <>
      <div id="LoginRoot" className="login-root">
        <div id="LoginWrapper" className="login-wrapper">
          <div className="d-flex flex-column flex-root login-wrapper-inner">
            {/*begin::Login*/}

            <div
              className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
              id="kt_login"
            >
              {/*begin::Content*/}
              <div className="d-flex flex-column  position-relative p-7 login-left">
                {/* begin::Content body */}
                <div className="d-flex flex-column-fluid flex-center mt-lg-0 login-left-top">
                  <Suspense fallback={<LayoutSplashScreen />}>

                    <Switch>
                      <ContentRoute path="/auth/login" component={Login} />
                      <ContentRoute path="/auth/registration" component={Registration} />
                      <ContentRoute path="/auth/reset-password" component={ResetPassword} />
                      <ContentRoute path="/auth/user/site/feedback" component={Feedback} />
                      <ContentRoute path="/auth/user/VideoCall" component={VideochatCtms} />
                      <ContentRoute path="/auth/user/email_verify" component={RegisterEmailVerify} />
                      <ContentRoute
                        path="/auth/forgot-password"
                        component={ForgotPassword}
                      />
                      <ContentRoute path="/auth/user/policy" component={Policies} />
                      <ContentRoute path="/auth/user/cookies" component={Cookiespage} />
                      <ContentRoute path="/auth/user/terms" component={TermsOfUse} />

                      <Redirect from="/auth" exact={true} to="/auth/login" />
                      <Redirect to="/auth/login" />
                    </Switch>
                  </Suspense>

                </div>
                {/*end::Content body*/}

              </div>
              {/*end::Content*/}
              {/*begin::Aside*/}
              <Carousel fade id="auth-page">
                {carouselData?.map(data => {
                  return [
                    <Carousel.Item variant="dark">
                      <img
                        className="d-block w-100"
                        src={data.content}
                        alt="First slide"
                      />
                    </Carousel.Item>

                  ]
                })}
              </Carousel>
            </div>
            {/*end: Aside Container*/}
            {/*end::Aside*/}
          </div>
          {/*end::Login*/}

          <div className="copyright-footer-wrap">
            <p className="copyright-footer">
              <img className="footer-logo" src="/media/misc/footer-icon.png" alt="" />
              Copyright © 2021 - 2025 | RealTime Participant Portal {sessionStorage.getItem("VersionNumber")} | All Rights
              Reserved |{" "}
              <a

                href={`${process.env.REACT_APP_API_URL}/auth/user/policy`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{" "}
              | <a href={`${process.env.REACT_APP_API_URL}/auth/user/cookies`} target="_blank" rel="noopener noreferrer"> Cookies Policy</a>

            </p>
            <div className='appstore-icon-wrap' id="appstore-links">
              <a href="https://play.google.com/store/apps/details?id=com.realtimectms.msm" target="_blank" rel="noopener noreferrer">
                <img src="/media/misc/google-play.svg" alt="Google Play" width="100%" className="google-play" />
              </a>
              <a href="https://apps.apple.com/us/app/mystudymanager/id1592343328" target="_blank" rel="noopener noreferrer">

                <img src="/media/misc/app-store.svg" alt="App Store" width="100%" className="google-play" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  )
}

export default AuthPage